/* src/Carousel.css */
.carousel-container {
    width: 100%;
    margin: auto;
    position: relative;
}

.carousel {
    position: relative;
    overflow: hidden;
    /* box-shadow: 5px 5px black; */
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease;
}

.carousel-item {
    min-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}


.carousel-btns {
    width: min-content;
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    gap: 1.5rem;
    /* border: 1px solid red; */
    padding: 0;
}

.carousel-control {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    height: 3rem;
    width: 3rem;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 50%;
    cursor: pointer;
    display: none;
}

.carousel-pagination {
    display: flex;
    justify-content: center;
    gap: 14px;
    padding: 10px 0;
}

.carousel-dot {
    width: 10px;
    height: 10px;
    background-color: #aaaaaa;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.carousel-dot.active {
    background-color: rgb(20, 20, 20);
    width: 60px;
    transition: all 0.3s;
}

@media (min-width: 800px) {
    .carousel:hover .carousel-control {
        display: block;
    }
}