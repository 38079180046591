#complaints {
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.complaint {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr;
    place-items: center;
    padding: 1.2rem 0;
    border-bottom: 1px solid #EDEDED;
}

.complaint p {
    text-align: center;
}

.complaint .status {
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.green {
    color: rgb(0, 78, 0);
    background-color: rgb(216, 255, 157);
}

.red {
    color: rgb(218, 0, 0);
    background-color: rgb(255, 198, 198);
}

.actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
}

.top h1 {
    color: #6A6A8F;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 1rem 2rem;
}

.headers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1.5fr;
    padding: 1rem 0;
    border-top: 1px solid #EDEDED;
    border-bottom: 1px solid #EDEDED;
}


.header-key {
    text-align: center;
    color: #717193;
    font-size: 1rem;
    font-weight: 500;
}