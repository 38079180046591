.contact {
    padding: 3rem 1rem;
}

.contact-details {
    padding: 1rem 0;
}

.contact-details h2 {
    font-size: 1.1rem;
    font-weight: 400;
    /* border: 1px solid black; */
    padding: 1rem;
    border-radius: 12px;
    margin: 1rem 0;
    background-color: beige;
    color: rgb(97, 79, 0);

}

.contact-details h2 span {
    font-weight: 700;
    margin-right: 0.5rem;
}

.add-text {
    padding: 0 1rem;
    font-size: 0.85rem;
    text-align: center;
    font-weight: 600;
}

.batman-img {
    width: 50%;
    margin: 1.5rem;
}

@media (min-width:800px) {
    .contact {
        padding: 3rem 25%;
    }
}