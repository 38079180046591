/* CustomSearchBar.css */
.search-bar {
    position: relative;
}

.search-input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.116);
    border-radius: 8px;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    padding-left: 2.5rem
}

.search-input::placeholder {
    color: #494949;
    font-weight: 500;
    font-size: 0.9rem;
}

.search-button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    width: 1.4rem;
}