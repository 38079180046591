#playground {
    display: grid;
    grid-template-columns: 2fr 8fr;
    background-color: #F3F4F6;
    padding: 0 1rem;
    min-height: 100vh;

}

.section-header {
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.8rem;
    color: #2c2974;
    margin: 1rem 0;
}

.right {
    width: 100%;
}