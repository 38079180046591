.draft {
    background-color: white;
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    transition: all 0.2s;
    margin-bottom: 1rem;
    position: relative;
}

.draft-title {
    min-width: 70%;
}

.draft:hover {
    box-shadow: 0 0 10px 5px rgba(95, 95, 95, 0.1);
    transition: all 0.2s;
}

.draft-actions {
    width: 30%;
    display: flex;
    justify-content: space-evenly;
}

.background-layer {
    background-color: rgba(0, 0, 0, 0.089);
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
}

.kebab-menu {
    z-index: 3;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 1rem;
    bottom: 110%;
    background-color: white;
    padding: 0.5rem 2rem;
    border-radius: 8px;
    gap: 1rem;
    box-shadow: 0 0 10px 5px rgba(95, 95, 95, 0.1);
}

.kebab-menu .btn:nth-child(1):hover {
    color: red;
}

.kebab-menu .btn:nth-child(2):hover {
    color: rgb(255, 183, 0);
}