.footer {
    background-color: #000000;
    color: #fff;
    padding: 4rem 2rem;
    text-align: center;
}

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer-text {
    font-family: 'Times New Roman';
    font-size: 2.1rem;
    text-align: left;
}

.foot-header {
    font-size: 1.4rem;
    margin-top: 2rem;
}

.footer-subtext {
    padding: 0.25rem 0;
    font-size: 1rem;
    color: #999999;
    font-family: Poppins;
    text-align: left;
}

.footer-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-link {
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
}

.copyright {
    padding: 0.5rem;
    background-color: #1d1d1d;
    text-align: center;
    color: #a3a3a3;
}

.social {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem;
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
    margin-top: 2rem;
}

.social a {
    cursor: pointer;
}

.social a img {
    width: 1.75rem;
}

.email-form {
    display: flex;
    align-items: center;
}

.email-form input {
    outline: none;
    border: 1px solid white;
    background-color: #000000;
    border-radius: 0;
    height: 3rem;
    width: 80%;
    border-right: 0px;

    color: #fff;
}

.email-form button {
    padding: 0 1rem;
    height: 3rem;

    font-family: Manrope;
    font-size: 1.1rem;
    font-weight: 400;
    border: 1px solid white;

    cursor: pointer;
}

@media (min-width:800px) {
    .footer {
        padding: 3rem 25%;
        top: 10vh;
    }

    .footer-container {
        align-items: center;
    }

    .footer-links {
        align-items: center;
    }

    .copyright {
        top: 10vh;
    }

}