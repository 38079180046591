.editorpanel {
    background-color: #F3F4F6;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 4rem;
}

:root {
    --jd-color-background-default: #ffffff;
    --jd-color-border: #495c76;
    --jd-color-panel: #1e293b;
    --jd-color-icon: #95a4b9;
}

.top-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem 2rem;
}

.back-btn {
    font-weight: 500;
    color: rgb(77, 77, 77);
    text-decoration: none;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 50%;
}

.back-btn:hover {
    background-color: rgb(235, 235, 235);
}

.back-btn:active {
    background-color: rgb(218, 218, 218);
}

#title {
    background: none;
    border: none;
    font-size: 2rem;
    color: black;
    outline: none;
}

#title::placeholder {
    color: black;
}

#title:focus {
    box-shadow: none;
}

#meta {
    margin: 0;
    border: 1px solid #D9D9D9;
    height: 3rem;
    padding: 0.5rem;
    border-radius: 8px;
}

#meta:focus {
    outline: none;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.059);
}

#meta-label {
    font-size: 0.9rem;
    margin-top: 1.5rem;
    color: #2d2d2d;
    font-weight: 500;
    padding-left: 5px;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
}

#categories {
    background-color: white;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.104);
    border-radius: 8px;

    font-weight: 500;
    font-size: 0.9rem;
}

#categories :focus {
    outline: none;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.059);
}

.action-btns {
    background-color: white;
    display: flex;
    gap: 1rem;
    padding: 0.35rem;
    border-radius: 8px;
}

select,
button {
    cursor: pointer;
}

button:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.059);
}

option {
    background-color: white;
    color: #424070;
}



.nav-link,
.back {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
}

.nav-link:hover,
.nav-link:active,
.back:hover {
    color: #424070;
}


.backdrop {
    height: 100vh;
    width: 100vw;
    z-index: 98;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #3030302f;
    backdrop-filter: blur(5px);
}

.dnd-close-btn {
    position: absolute;
    right: 10rem;
    top: 5rem;
    font-size: 3rem;
    color: black;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
}

.upload-img-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #003c96;
    padding: 1rem;
    font-size: 1.1rem;
    border-radius: 2rem;
    gap: 1rem;
    border: none;

    position: absolute;
    bottom: 5rem;
    right: 50%;
    transform: translateX(50%);
    cursor: pointer;
}

.upload-img-btn:active {
    background-color: #00193f;
}


@media screen and (max-width:800px) {
    .editorpanel {
        padding: 5rem 1rem;
    }

    .top-bar {
        flex-direction: column;
    }

}