.drop-container {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
    z-index: 99;
    width: 60%;
    height: 60%;
    box-shadow: 0 0 10px 5px rgba(105, 105, 105, 0.26);
    border-radius: 24px;
    background-color: white;
}

.drop-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s ease;
}

.drop-button:hover {
    background-color: #45a049;
}

.drag-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #333;
    font-size: 1.5rem;
    padding: 2rem;

}

.drop-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-color: #dae9ff; */
    height: 100%;
    border-radius: 16px;
    outline: 2px dashed #003c96;
    transition: border-color 0.3s ease;
    color: #003c96;
}


.drag-area.drag-active {
    border-color: #45a049;
    background-color: #e9ffe9;
}

.file-input-wrapper {
    cursor: pointer;
    /* border: 1px solid #003c96; */
    width: 100%;
    height: 20%;
    margin: 0;
    background-color: #dae9ff;
    border-radius: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.file-input-wrapper p {
    color: #003c96;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.file-input-wrapper p span {
    font-size: 2rem;
}

.file-input {
    display: none;
    margin: 0;
    padding: 0;
}

.dnd {
    font-size: 4rem;
    color: #003c96;
}