.services-page {
    padding: 1rem;
    background-color: white;
    color: black;
}

.services-page .hero {
    text-align: left;
    padding: 1rem 0;
}

.services-page .hero h1 {
    font-family: 'Times New Roman';
    font-size: 2.2rem;
    font-weight: 300;
    margin-bottom: 1rem;
}

.services-page .hero p {
    font-size: 1.1rem;
}

.services-page .turn-your-website,
.services-page .we-help-with,
.services-page .industries,
.services-page .process {
    margin: 3rem 0;
}

.services-page h2 {
    font-family: 'Times New Roman';
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.services-page p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.services-page .service-cta {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    background-color: black;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.25s;
}

.services-page .service-cta:hover {
    outline: 1px solid black;
    background: none;
    color: black;
    transition: all 0.25s;
}

.services-page ul {
    list-style-type: disc;
    margin-left: 2rem;
    margin-bottom: 1.5rem;
}

.services-page li {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

@media (min-width: 800px) {
    .services-page {
        padding: 0 2rem;
    }

    .services-page .hero h1 {
        font-size: 2.6rem;
    }

    .services-page h2 {
        font-size: 2.2rem;
    }

    .services-page .service-cta {
        padding: 0.5rem 1.2rem;
        font-size: 1.1rem;
    }

    .services-page .hero {
        padding: 3rem 0;
    }
}