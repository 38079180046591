#side-panel {
    position: fixed;
    height: 95vh;
    width: 20%;
    top: 0;
    left: 0;
}

.logo-header {
    background-color: #0F172A;
    padding: 1rem;
}

.logo-header img {
    width: 50%;
}

.new-blog-btn {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: left;
    color: #DCE6FF;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 6px;
    background-color: #2B5793;
}

.panel-container {
    height: 100%;
    background-color: #0F172A;
    padding: 1rem;
    position: relative;
}

.blank-text {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    color: rgb(88, 88, 88);
}

.navigation {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.panel-nav-link {
    padding: 0.5rem 1rem;
    color: #C3D5EE;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: left;
}

.panel-nav-link img,
.new-blog-btn img {
    width: 1.5rem;
    height: 1.5rem;
}

.navigation .panel-nav-link:hover,
.active {
    background-color: #1E293B;
}

.options {
    position: absolute;
    bottom: 1rem;
}

.options .panel-nav-link {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

@media (max-width:800px) {
    #side-panel {
        transform: translateX(-100%);
    }
}