.testimonials {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin-bottom: 4rem;
    padding: 2rem 0;
}


.testimonial {
    border: 1px solid black;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 5px 5px 0 0 black;
    width: 80%;
}

.testimonial-msg {
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
}

.testimonial-provider {
    font-size: 1.3rem;
    color: rgb(29, 29, 29);
    margin-top: 1rem;
}

.testimonial-org {
    font-size: 1rem;
    color: rgb(82, 82, 82);
    font-weight: 500;
}