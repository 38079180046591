#about {
    padding: 3rem 1rem;
}

.profile {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
}

.dp img {
    border-radius: 50%;
    border: 2px solid black;
    /* box-shadow: 4px 4px black; */
}

.profile-details h2 {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
}

.profile-details p {
    text-align: center;
    color: rgb(88, 88, 88);
    font-size: 1rem;
    font-weight: 300;
}

.about-para {
    color: rgb(0, 0, 0);
    padding: 1rem;
    font-size: 1.2rem;
}