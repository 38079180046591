#hero-top {
    position: fixed;
    left: 0;
    top: 10vh;
    height: 90vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-45deg, rgb(207, 213, 255) 0%, rgb(227, 233, 255) 30%, rgb(255, 250, 230) 100%);
    padding: 0.5rem;
}


.header h1 {
    font-size: 2.5rem;
    color: rgb(20, 20, 20);
    font-weight: 500;
    font-family: Poppins, serif;
    width: 100%;
}

.header-title {
    font-size: 3.8rem;
    color: rgb(20, 20, 20);
    font-family: Poppins, serif;
    font-weight: 500;
    text-align: center;
    margin: 0.75rem 0;
    line-height: 95%;
    letter-spacing: -0.1rem;
}

.header-subtitle {
    font-size: 1rem;
    color: rgb(126, 126, 126);
    font-weight: 500;
    text-align: center;
}

.cta-text {
    font-size: 1.16rem;
    color: rgb(53, 53, 53);
    font-family: Poppins, sans-serif;
    text-align: center;
    font-weight: 500;
    margin: 1rem;
}

.content {
    max-width: 80%;
    text-align: center;
}

.socials {
    display: flex;
    gap: 1.3rem;
}

.social-icon {
    width: 1.3rem;
}

.buttons-container {
    padding: 1rem 0;
    display: flex;
    /* border: 1px solid; */
    justify-content: center;
    width: 100%;
    gap: 1rem;
    /* background-color: rgb(233, 233, 233); */
}

.hero-bottom {
    position: relative;
    top: 90vh;
    padding: 2rem;
    z-index: 100;
    background-color: white;
    box-shadow: 0 -3px 5px 2px rgba(179, 179, 179, 0.116);
}


.sign-up-form {
    text-align: center;
    margin: 20px 0;
    max-width: 100%;
}

.input {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid black;
    border-radius: 5px;
}

.cta-button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
}

.hero-bottom .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    padding-top: 2rem;
}

.hero-bottom .card {
    border: 1px solid black;
    padding: 1rem;
    border-radius: 12px;
    background-color: white;
    transition: all 0.25s;
}

.hero-bottom .card:hover {
    box-shadow: 3px 3px 0 0 rgb(0, 0, 0);
    transition: all 0.25s;
}

.card-title {
    font-family: Poppins, serif;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0.8rem;

}

.card-description {
    font-size: 1rem;
}

.hero-bottom .about,
.hero-bottom .services,
.hero-bottom .why-us {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 4rem;
}

.hero-bottom .why-us {
    background-color: rgb(247, 247, 247);
    margin-top: 4rem;
    align-items: flex-start;
}

.hero-bottom .why-us ul {
    padding: 1rem 2rem;
}


.hero-bottom .services .cards-container {
    max-width: 100%;
}

.section-title {
    font-size: 2.5rem;
    font-weight: 500;
    color: rgb(20, 20, 20);
}

.section-title-2 {
    font-size: 1.55rem;
    font-weight: 500;
    color: rgb(20, 20, 20);
}

.hero-bottom .marketing-guide,
.hero-bottom .analysis,
.hero-bottom .get-customers,
.hero-bottom .faq {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.75rem;
    border-top: 1px solid black;
}

@media (max-width: 800px) {

    .hero-bottom {
        padding: 1rem;
    }

    .header-title {
        font-size: 2.5rem;
    }

    .section-title {
        font-size: 2rem;
    }

    .header-subtitle {
        font-size: 0.9rem;
    }

    .cta-text {
        font-size: 1rem;
    }

    .content {
        max-width: 100%;
    }

    .hero-bottom .about,
    .hero-bottom .services,
    .hero-bottom .why-us {
        padding: 1rem;
    }
}